import {talk} from "./ALI_Ajax";

export function log_message(log_level, message) {
    talk(
        "ali_api/ALILogger_Handler.php",
        "LOG_MESSAGE",
        {log_level:log_level, message:message},
        function(operation, sent_data, return_data) {
            if(return_data['status'] !== 'success') {
                console.log(return_data);
            }
        }
    );
}
