import React, { useState, useEffect } from "react";
import {talk} from "./ALI_Ajax.js";
import EMStyles from "./styles/ExamineeManagerStyles.module.css";
import GenStyles from "./styles/GenStyles.module.css";

const RegisterExamineeForTest = ({ examinee, handleModalClosing }) => {
    const [completedTests, setCompletedTests] = useState([]);
    const [uncompletedTests, setUncompletedTests] = useState([]);
    const [availableTests, setAvailableTests] = useState([]);
    const [selectedTestIndex, setSelectedTestIndex] = useState(null);

    //USE EFFECTS
    useEffect(() => {
        load_tests();
    }, []);

    //AJAX FUNCTIONS
    const load_tests = () => {
        talk(
            "ali_api/ALITests_Handler.php",
            "GET_TEST_ASSIGNMENT_DATA_FOR_EXAMINEE",
            {user_id: examinee['user_id']},
            load_tests_handler
        );
    }
    const load_tests_handler = (operation, sent_data, return_data) => {
        console.log(return_data);
        if(return_data['assignable_tests'] && return_data['assignable_tests']['status'] === 'success') {
            setAvailableTests(return_data['assignable_tests']['message']);
        }

        if(return_data['completed_tests'] && return_data['completed_tests']['status'] === 'success') {
            setCompletedTests(return_data['completed_tests']['message']);
        }

        if(return_data['uncompleted_tests'] && return_data['uncompleted_tests']['status'] === 'success') {
            setUncompletedTests(return_data['uncompleted_tests']['message']);
        }
    }
    const assign_test = (test_id) => {
        talk(
            "ali_api/ALITests_Handler.php",
            "ASSIGN_TEST_TO_EXAMINEE",
            {user_id: examinee['user_id'], test_id: test_id},
            load_tests_handler
        );
    }
    const unassign_test = (test_id) => {
        talk(
            "ali_api/ALITests_Handler.php",
            "UNASSIGN_TEST_TO_EXAMINEE",
            {user_id: examinee['user_id'], test_id: test_id},
            load_tests_handler
        );
    }
    // const assign_test_handler = (operation, sent_data, return_data) => {
    //     if(return_data['status'] && return_data['status'] === 'success') {
    //         setAvailableTests(return_data['message']);
    //     } else {
    //         alert("Failed to register student for test.");
    //         console.log(return_data);
    //     }
    //
    // }

    //OTHER FUNCTIONS
    const toggle_test_details = (index) => {
        setSelectedTestIndex(index === selectedTestIndex ? null : index);
    }
    const handle_close_modal = () => {
        handleModalClosing();
    }
    const view_test_result = () => {
        alert("Not implemented yet.");
    }

    return (
        <div className={EMStyles.modal_container}>
        <div className={EMStyles.modal_content}>

        {/* Close Button */}
        <button className={EMStyles.modal_close_button} onClick={handle_close_modal}>
            X
        </button>

        <div className={EMStyles.container}>
            <h1 className={EMStyles.title}>Register Examinee for Test</h1>

            {/* Examinee Info */}
            <div className={EMStyles.examinee_info}>
                <p><strong>First Name:</strong> {examinee.firstname}</p>
                <p><strong>Last Name:</strong> {examinee.lastname}</p>
                <p><strong>Email:</strong> {examinee.email}</p>
            </div>

            {/* Completed Tests */}
            <div className={EMStyles.test_list}>
                <h2 className={EMStyles.section_title}>Completed Tests</h2>
                <ul className={EMStyles.list}>
                    {completedTests.length > 0 ? (
                        completedTests.map((test, index) => (
                            <li
                                key={index}
                                className={`${EMStyles.list_item} ${selectedTestIndex === test.test_id ? EMStyles.list_item_selected : ""}`}
                                onClick={() => toggle_test_details(test.test_id)}
                            >
                                <div className={EMStyles.test_name}>{test.test_name}</div>
                                <div className={EMStyles.test_description}>{test.description}</div>
                                <div className={EMStyles.assign_test_button}>
                                    <button
                                        onClick={() => view_test_result(test.test_id)}
                                    >View Test Results</button>
                                </div>
                            </li>
                        ))
                    ) : (
                        <li className={EMStyles.list_item_empty}>No completed tests</li>
                    )}
                </ul>
            </div>

            {/* Uncompleted Tests */}
            <div className={EMStyles.test_list}>
                <h2 className={EMStyles.section_title}>Uncompleted Tests</h2>
                <ul className={EMStyles.list}>
                    {uncompletedTests.length > 0 ? (
                        uncompletedTests.map((test, index) => (
                            <li
                                key={index}
                                className={`${EMStyles.list_item} ${selectedTestIndex === test.test_id ? EMStyles.list_item_selected : ""}`}
                                onClick={() => toggle_test_details(test.test_id)}
                            >
                                <div className={EMStyles.test_name}>{test.test_name}</div>
                                <div className={EMStyles.test_description}>{test.description}</div>
                                <div className={EMStyles.assign_test_button}>
                                    <button
                                        onClick={() => unassign_test(test.test_id)}
                                    >Unassign Test</button>
                                </div>
                            </li>
                        ))
                    ) : (
                        <li className={EMStyles.list_item_empty}>No uncompleted tests</li>
                    )}
                </ul>
            </div>

            {/* Available Tests */}
            <div className={EMStyles.test_list}>
                <h2 className={EMStyles.section_title}>Available Tests</h2>
                <ul className={EMStyles.list}>
                    {availableTests.length > 0 ? (
                        availableTests.map((test, index) => (
                            <li
                                key={index}
                                className={`${EMStyles.list_item} ${selectedTestIndex === test.test_id ? EMStyles.list_item_selected : ""}`}
                                onClick={() => toggle_test_details(test.test_id)}
                            >
                                <div className={EMStyles.test_name}>{test.test_name}</div>
                                <div className={EMStyles.test_description}>{test.description}</div>
                                <div className={EMStyles.assign_test_button}>
                                    <button
                                        onClick={() => assign_test(test.test_id)}
                                    >Assign this Test</button>
                                </div>
                            </li>
                        ))
                    ) : (
                        <li className={EMStyles.list_item_empty}>No available tests</li>
                    )}
                </ul>
            </div>
        </div>
        </div>
        </div>
    );
};

export default RegisterExamineeForTest;