import React, {useEffect, useState, useRef} from "react";
import GenStyles from "./styles/GenStyles.module.css";
import {talk} from "./ALI_Ajax.js";
import AStyles from "./styles/AdminStyles.module.css";
import {useUser} from "./UserContext";
import LoginModule from "./login_module";



const Admin = () => {
    const {userId, finishedLoggingIn, is_user_in_role} = useUser();
    const [logFiles, setLogFiles] = useState(false);
    const [selectedLogFileContent, setSelectedLogFileContent] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    //USE EFFECTS
    useEffect(() => {
        if(finishedLoggingIn) {
            if(!is_user_in_role('ADMIN')) {
                //Redirect to home page
                document.location.href='./';
            } else {
                //Load Log Files List
                load_log_files_list();

            }
        }        
    }, [finishedLoggingIn]);
    useEffect(() => {
        console.log(logFiles);
    }, [logFiles])
   

    //AJAX FUNCTIONS
    const load_log_files_list = () => {
        talk(
            "ali_api/ALIAdmin_Handler.php",
            "GET_LIST_OF_LOG_FILES",
            null,
            load_log_files_list_handler
        );
    }
    const load_log_files_list_handler = (operation, sent_data, return_data) => {
        console.log(return_data);
        setLogFiles(return_data);
    }
    const load_log_file_content = (filename) => {
        talk(
            "ali_api/ALIAdmin_Handler.php",
            "LOAD_LOG_FILE_CONTENT",
            {filename: filename},
            load_log_file_content_handler
        );
    }
    const load_log_file_content_handler = (operation, sent_data, return_data) => {
        console.log(return_data);
        setSelectedLogFileContent(return_data['message']);
    }

    //OTHER FUNCTIONS
    const handle_log_file_click = (item) => {
        // Toggle selection: If the same file is clicked again, deselect it
        if (selectedFile === item) {
            setSelectedFile(null);
            setSelectedLogFileContent(false);
        } else {
            setSelectedFile(item);
            load_log_file_content(item); // Load the selected file content
        }
    };


    //RENDERING
    return (
        <>
        {
            finishedLoggingIn  && is_user_in_role('ADMIN') ?
                <>
                    <div className={GenStyles.container} style={{marginTop: "80px"}} id="showcase">
                        <h1 className={GenStyles.jumbo}><b>ALI</b></h1>
                        <h1 className={`${GenStyles.xxxlarge} ${GenStyles.text_umkc_blue}`}><b>Admin Page</b></h1>
                        <hr style={{width: "50px", border: "5px solid #ffd52f"}} className={GenStyles.round}/>
                    </div>
                    {userId ?
                        
                        <div className={AStyles.container}>
                            <h1 className={AStyles.title}>Review Log Files</h1>

                            <div className={AStyles.file_list_container}>
                                {logFiles && logFiles.map((item, index) => (
                                    <div
                                        key={index}
                                        className={`${AStyles.file_list_item} ${selectedFile === item ? AStyles.selected_file : ''}`}
                                        onClick={() => handle_log_file_click(item)}
                                    >
                                        {item}
                                    </div>
                                ))}
                            </div>
                            <div className={selectedLogFileContent ? AStyles.table_container : GenStyles.hidden}>
                                <table className={AStyles.table}>
                                    <thead>
                                    <tr>
                                        <th className={AStyles.th}>Time</th>
                                        <th className={AStyles.th}>Log Level</th>
                                        <th className={AStyles.th}>Message</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {selectedLogFileContent && selectedLogFileContent.map((item, index) => (
                                        <tr key={index} className={AStyles.tr}>
                                            <td className={AStyles.td}>{item.timestamp}</td>
                                            <td className={AStyles.td}>{item.log_level}</td>
                                            <td className={AStyles.td}>{item.message}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>

                            <div>Other Admin Stuff</div>
                        </div>
                        : <LoginModule/>}
                </> :
                <div>loading</div>
            }
        </>
    )
}

export default Admin;
