import React, {useEffect, useState, useRef} from "react";
import GenStyles from "./styles/GenStyles.module.css";
import { talk } from "./ALI_Ajax.js";
import TStyles from "./styles/TestStyles.module.css";
import {useUser} from "./UserContext";
import LoginModule from "./login_module";



const TakeTest = (props) => {
    const {userId, finishedLoggingIn} = useUser();


    //USE EFFECTS
    useEffect(() => {

    }, [userId]);

    //AJAX FUNCTIONS


    //OTHER FUNCTIONS



    //RENDERING
    return (
        <>
            {
                finishedLoggingIn ?
                    <>

                        {userId ?
                            <div className={TStyles.container}>
                                <h1 className={TStyles.title}>Test Name</h1>


                                <div>Content</div>
                            </div>
                            : <LoginModule/>}
                    </> :
                    <div>loading</div>
            }
        </>
    )
}

export default TakeTest;
