import React, {useContext, useState} from "react";
import GenStyles from "./styles/GenStyles.module.css";
import LoginStyles from "./styles/LoginStyles.module.css";
import {talk} from "./ALI_Ajax";
import {useUser} from "./UserContext";
import {log_message} from "./ALI_Logger";

const LoginModule = (props) => {
    //DATA
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const {login, firstName, check_login_status} = useUser();
    const [loginError, setLoginError] = useState("");

    //AJAX FUNCTIONS
    const process_login = () => {
        talk(
            "ali_api/ALISecurity_Handler.php",
            "PROCESS_LOGIN",
            {
                email: email,
                password:password
            },
            process_login_handler
        );
    }
    const process_login_handler = (operation, sent_data, return_data) => {
        if(return_data['status'] === 'success') {
            setLoginError("");
            login(
                return_data['user_data']['email'],
                return_data['user_data']['user_id'],
                return_data['user_data']['firstname']
            );

            //Redirect to Login Page
            const urlPath = window.location.pathname;
            const lastPart = urlPath.substring(urlPath.lastIndexOf('/') + 1);
            if(lastPart !== '') {
                document.location.href = '/';
            }
        } else {
            log_message("SECURITY", "Login failure - Email: " + sent_data['email']);
            setLoginError("Failed to login.");
        }
    }

    //GENERAL FUNCTIONS
    const block_form_submission = (event) => {
        event.preventDefault();

        //TODO: Add validation code here

        process_login();
    }

    return <>
        <form
            id="loginForm"
            className={LoginStyles.login_form}
            method="POST"
            onSubmit={block_form_submission}
        >
            <div className={LoginStyles.login_row}>
                <label
                    className={LoginStyles.login_label}
                    htmlFor="username"
                >Email:</label>
                <input
                    type="text"
                    id="email"
                    name="email"
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                    required
                />
            </div>
            <div className={LoginStyles.login_row}>
                <label
                    className={LoginStyles.login_label}
                    htmlFor="password"
                >Password:</label>
                <input
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={event => setPassword(event.target.value)}
                    required
                />
            </div>
            <div className={LoginStyles.login_row}>
                <button className={LoginStyles.login_button} type="submit">Login</button>
            </div>
        </form>
        <div className={LoginStyles.login_error_message}>{loginError}</div>
    </>
}

export default LoginModule;